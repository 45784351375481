import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

function Estatuto({lang}) {
    return <div>
        <div id='estatuto-riesgo' className='estatuto-riesgo col-10'>
            <h3>{lang === 'en' ? 'AML & KYC Policies' : 'Políticas AML y KYC'}</h3>
            <div className='estatuto-txt mt-40'>
            <p>
                {lang === 'en' ? (
                    <>
                    By contracting or using our services and platforms, you implicitly acknowledge that you have understood and accepted the risks of trading and market products, as well as trading with leverage. Also, that your capital carries a high degree of risk, and you could lose it. Any opinions, chats, messages, news, research, analyses, prices, or other information contained on this website are provided as general market information for dissemination and general culture purposes only, and do not constitute investment advice, recommendation, or guarantee of results.
                    </>
                ) : (
                    <>
                    Al contratar o usar nuestros servicios y plataformas, usted implícitamente reconoce que ha comprendido y aceptado los riesgos de operar “trading” y los productos del mercado, así como operaciones con apalancamiento. <br />
                    También que su capital conlleva un alto grado de riesgo y podría perderlo. <br />
                    Toda opinión, chat, mensaje, noticia, investigación, análisis, precio u otra información contenida en este sitio web se proporciona como información general del mercado con fines de divulgación y de cultura general únicamente, y no constituye un consejo de inversión, recomendación, ni tampoco garantía de resultado.
                    </>
                )}
             </p>


             <p>
                {lang === 'en' ? (
                    <>
                    Information from any website should never be relied upon to make decisions, nor is it any guarantee or safe offer. Opinions, market data, or any other content are subject to change at any time without notice.
                    </>
                ) : (
                    <>
                    Nunca se debe confiar en la información de ningún sitio web para tomar decisiones, ni tampoco es garantía alguna u oferta segura. Las opiniones, datos de mercado o cualquier otro contenido están sujetos a cambios en cualquier momento sin previo aviso.
                    </>
                )}
             </p>
                
             <p>
                {lang === 'en' ? (
                    <>
                    You release ALTAIR from any liability arising from market risks for loss or damage, both of profits and capital, that may arise directly or indirectly from the transaction.                    </>
                ) : (
                    <>
                    Usted libera a ALTAIR, de toda responsabilidad derivada de los riesgos de mercado por pérdidas o daños, tanto de las ganancias como del capital, que pueda surgir directa o indirectamente de la operación.
                    </>
                )}
             </p>

             <p>
                {lang === 'en' ? (
                    <>
                    We do not recommend the use of technical analysis as the sole means of making trading decisions. We don't recommend making hasty trading decisions. You should always understand that your PAST IS NOT NECESSARILY INDICATIVE OF FUTURE RESULTS. 
                    </>
                ) : (
                    <>
                    No recomendamos el uso del análisis técnico como único medio para tomar decisiones comerciales. No recomendamos tomar decisiones comerciales apresuradas. Siempre debe comprender que el tu PASADO NO ES NECESARIAMENTE INDICATIVO DE RESULTADOS FUTUROS.
                    </>
                )}
             </p>
            </div>
        </div>
    </div>;
}

export default Estatuto;