import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Menu from './Menu';


const Headersmall = ({ path, handleLanguageChange, lang }) => {
  const { pathname } = useLocation();
  if (pathname === "/") {
    return (
      <div className='header'>
        <div id="navbar" className='header-pinned w-100 d-flex justify-content-between'>
          <div>
            <Link to="/">
              <img alt="ALTAIR logo" className="logo header-logoh" id="logo-pinned" src="/imgs/logo_header.svg" />
              <img alt="ALTAIR logo" className="logo" id="logo-scroll" src="/imgs/logo_scroll.svg" />
            </Link>
          </div>

          <div className='navigation'>
            <Menu handleLanguageChange={handleLanguageChange} lang={lang} />
          </div>
        </div>
      </div>
    );
  }
};

export default Headersmall;
