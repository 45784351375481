import {HashRouter as Router, Route, Routes} from 'react-router-dom';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import CookieConsent from 'react-cookie-consent';
import Wa from './components/Wa';
import Footer from './components/Footer';
import Home from './views/Home';
import About from './views/About';
import Estrategias from './views/Estrategias';
import Contact from './views/Contact';
import Login from './views/Login';
import Header from './components/Header';
import Headerh from './components/Headerh';
import Estatuto from './views/Estatuto';
import Calculadora from './views/Calculadora';
import Brandbook from './views/Brandbook';
import Privacy from './views/Privacy';
import ScrollToTop from './components/Scroll';
import { useState } from 'react';

function App() {

  const [lang, setLang] = useState('es');

  const handleLanguageChange = (selectedLang) => {
    setLang(selectedLang);
  };

  
  return (
    <Router>
      <ScrollToTop />
      <div className='d-flex flex-column min-vh-100'>
        <Header  lang={lang} handleLanguageChange={handleLanguageChange} />
        <Headerh lang={lang} handleLanguageChange={handleLanguageChange} />
        <Wa />     	
          <Routes>
            <Route path="/" element={<Home lang={lang} handleLanguageChange={handleLanguageChange} />} />
            <Route path="/about" element={<About lang={lang} handleLanguageChange={handleLanguageChange} />} />
            <Route path="/strategy" element={<Estrategias lang={lang} handleLanguageChange={handleLanguageChange} />} />
            <Route path="/contact" element={<Contact lang={lang} handleLanguageChange={handleLanguageChange} />} />
            <Route path="/login" element={<Login lang={lang} handleLanguageChange={handleLanguageChange} />} />
            <Route path="/estatuto" element={<Estatuto lang={lang} handleLanguageChange={handleLanguageChange} />} />
            <Route path="/calculadora" element={<Calculadora lang={lang} handleLanguageChange={handleLanguageChange} />} />
            <Route path="/brandbook" element={<Brandbook lang={lang} handleLanguageChange={handleLanguageChange} />} />
            <Route path="/privacy" element={<Privacy lang={lang} handleLanguageChange={handleLanguageChange} />} />
          </Routes>
        <Footer lang={lang} handleLanguageChange={handleLanguageChange} />   		
      </div>
      <CookieConsent
        // debug
        enableDeclineButton
        buttonText="Aceptar"
        declineButtonText="Rechazar"
        hideOnDecline={false}
        style={{
          background: '#F6F6F6',
        }}
        className="cookies-banner"
      >
        <span className='xsmall c-black'>
        Utilizamos cookies propias y/o de terceros como gran parte de las webs en las que confías para analizar la navegación de los usuarios. <Link to="/privacy">Más sobre nuestras cookies</Link>
        </span>
      </CookieConsent>
    </Router>
  );

}

export default App;


