import React, { useRef, useEffect, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


// import required modules
import { Pagination, Navigation, Autoplay } from 'swiper/modules';

export default function Quotes({lang}) {
    const [index, setIndex] = useState(0);
    const [quoteList, setData] = useState([]);

    useEffect(() => {
      (async () => {
        try {
          const response = await fetch('https://altair-admin.onrender.com/api/testimonials/index');
          const jsonData = await response.json();
          setData(jsonData);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      })();
    }, []);

    const renderClassName = (id) => {
        switch (id) {
            case 1:
            case 4:
            case 7:
                return 'bg-pink';
            case 2:
            case 5: 
            case 8:   
                return 'bg-blue';
            case 3:
            case 6: 
            case 9:  
                return 'bg-green';
            default:
                return 'bg-pink';
        }
    };

    if (quoteList === null || quoteList.length == 0) return "Loading...";
    let quote = quoteList[index];

  return (
    <>
        <div className='d-flex justify-content-between align-items-center'>
            <div className='home-slider-prev home-slider-nav d-flex align-items-center justify-content-center home-desktop'>
                <img alt="previous" id="slider-prev" src="/imgs/slider_prev.svg" />
            </div>
            <div className='home-slider-wrapper d-flex justify-content-around'>

            <Swiper
                slidesPerView={1.07}
                // centeredSlides={true}
                spaceBetween={0}
                loop={true}
                // autoplay={{
                //     delay: 2500,
                //     disableOnInteraction: true,
                // }}
                initialSlide="3"
                pagination={{
                    el: '.swiper-pagination-d',
                    type: 'fraction',
                    formatFractionCurrent: function (number) {
                        return ('' + number).slice(-2);
                    },
                    formatFractionTotal: function (number) {
                        return ('' + number).slice(-2);
                    },
                    renderFraction: function (currentClass, totalClass) {
                        return '<span class="' + currentClass + '"></span>' +
                            ' de ' +
                            '<span class="' + totalClass + '"></span>';
                    }
                }}
                className="mySwiper rounded-30"
                navigation= {{
                    nextEl: ".home-slider-next",
                    prevEl: ".home-slider-prev",
                }}
                breakpoints={{
                    375: {
                        slidesPerView: 1.05, 
                      },
                    768: {
                      slidesPerView: 1.05, 
                    },
                    992: {
                      slidesPerView: 1.05,
                    },
                    1200: {
                      slidesPerView: 1.07,
                    },
                }}
                modules={[Pagination, Navigation, Autoplay]}
            >
                {quoteList.map((quote) => (
                <SwiperSlide key={quote.id}>
                    <div className={`home-slider-inner d-flex flex-column justify-content-between ${renderClassName(quote.id)}`}>                    
                        <p dangerouslySetInnerHTML={{ __html: lang === 'en' ? quote.quote_eng :quote.quote }} />

                        <span className='author'>{quote.author}</span>
                    </div>
                </SwiperSlide>
                ))}
            </Swiper>
        </div>
            <div className='home-slider-next home-slider-nav d-flex align-items-center justify-content-center home-desktop'>
                <img alt="next" id="slider-next" src="/imgs/slider_next.svg" />
            </div>
        </div>
        <div className='offset-1 mt-20'>
            <p className='swiper-pagination-d'></p>  
        </div> 

        <div className='home-mobile d-flex justify-content-between align-items-center'>
            <div className='home-slider-prev home-slider-nav d-flex align-items-center justify-content-center mt-60'>
                    <img alt="previous" id="slider-prev" src="/imgs/slider_prev.svg" />
            </div>

            <div className='home-slider-next home-slider-nav d-flex align-items-center justify-content-center mt-80 mt-md-60'>
                    <img alt="next" id="slider-next" src="/imgs/slider_next.svg" />
            </div>
        </div> 
        
    </>
  );
}
