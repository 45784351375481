import React, { useState } from 'react';
import { NavLink} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';


const Menu = ({handleLanguageChange, lang}) => {
  const [line_menu, setLineMenu] = useState("line_menu")
  const [wrapper_menu, setMiddleMenu] = useState("wrapper_menu")
  const [full_menu, setMenuClass] = useState("menu hidden")
  const [menu_open, setMenuOpen] = useState("menu_open visible")
  const [menu_close, setMenuClose] = useState("menu_close hidden")
  const [isMenuClicked, setIsMenuClicked] = useState(false)

  const updateMenu = () => {
    if(!isMenuClicked) {
      setLineMenu("line_menu open")
      setMenuClass("full_menu visible")
      setMiddleMenu("wrapper_menu open")
      setMenuOpen("menu_open hidden")
      setMenuClose("menu_open visible")
    }
    else {
      setLineMenu("line_menu")
      setMenuClass("full_menu hidden")
      setMiddleMenu("wrapper_menu")
      setMenuOpen("menu_open visible")
      setMenuClose("menu_open hidden")
    }
    setIsMenuClicked(!isMenuClicked)
  }

  const closeMenu = () => {
    setLineMenu("line_menu")
    setMenuClass("full_menu hidden")
    setMiddleMenu("wrapper_menu")
    setMenuOpen("menu_open visible")
    setMenuClose("menu_open hidden")
  }
  

  return (
    <>
      <div className='d-flex'>
        <div id="navbar-right" className='d-flex justify-content-between'>
          <li><NavLink activeClassName="nav-active" to="/about">{lang === 'en' ? 'About Us' : 'Nosotros'}</NavLink></li>
          <li><NavLink activeClassName="nav-active" to="/strategy">{lang === 'en' ? 'Strategies' : 'Estrategia'}</NavLink></li>
          <li><NavLink activeClassName="nav-active" to="/contact">{lang === 'en' ? 'Contact' : 'Contacto'}</NavLink></li>
          <li><NavLink activeclassname="nav-active" to="https://my.altairic.com/en/login" target="_blank">Log in</NavLink></li>
        </div>

        <div id='lang' className='d-flex justify-content-between'>
            {/* <li><a href to="/">Eng</a></li> */}
            <li onClick={() => handleLanguageChange('en')} className={`d-flex ${lang === 'en' && 'd-none'}`}>Eng</li>
            <li onClick={() => handleLanguageChange('es')} className={`d-flex ${lang === 'es' && 'd-none'}`}>Esp</li>
          </div>
      </div>

      <div id="navbar-mobile">
        <div className="menu-button" onClick={updateMenu}>
            <div className={wrapper_menu}>
              <div className={line_menu}></div>
              <div className={line_menu}></div>
              <div className={line_menu}></div>
            </div>
          </div>

        <div className={full_menu}>
          <div className="full-menu-inner">
            <li><NavLink to="https://my.altairic.com/en/login" onClick={updateMenu}>Log in</NavLink ></li>
            <li><NavLink activeClassName="nav-active" to="/about">{lang === 'en' ? 'About Us' : 'Nosotros'}</NavLink></li>
            <li><NavLink activeClassName="nav-active" to="/strategy">{lang === 'en' ? 'Strategies' : 'Estrategia'}</NavLink></li>
            <li><NavLink activeClassName="nav-active" to="/contact">{lang === 'en' ? 'Contact' : 'Contacto'}</NavLink></li>
            <div className='pt-35'>
              <li onClick={() => handleLanguageChange('en')} className={`d-flex ${lang === 'en' && 'd-none'}`}>Eng</li>
              <li onClick={() => handleLanguageChange('es')} className={`d-flex ${lang === 'es' && 'd-none'}`}>Esp</li>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Menu;
