import React, {useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';


const containerStyle = {
    width: '100%',
    height: '500px'
  };

  
   if (window.innerWidth <= 600) {
    containerStyle.height = '280px';
  } else {
    containerStyle.height = '500px';
  }
  

  const options = {
    zoomControl: true,
    scrollwheel: true,
    disableDoubleClickZoom: true,
  };

// ... (your imports and constants)

function Map() {
  const [contact, setContacts] = useState([]);
  useEffect(() => {
    fetch(`https://altair-admin.onrender.com/api/contact/1`)
        .then((response) => response.json())
        .then((data) => {
          setContacts(data);
        })
  }, []);

    const center = {
    lat: contact.latitude,
    lng: -99.16878570937266
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyBr0bwCqJMRFHFg_HmL2kNNOK5YbRx5-eA"
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    window.google.maps.event.addListenerOnce(map, 'tilesloaded', function() {
      map.setZoom(18);
      setMap(map);
    });
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={17}
      options={options}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {map && <Marker position={center} map={map} />}
    </GoogleMap>
  ) : (
    <div>Loading ...</div>
  );
}

export default Map;
