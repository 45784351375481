import React, {useEffect, useState} from 'react';
import { Link, useLocation } from 'react-router-dom';

const Footer = ({ path, lang }) => {
  const [contact, setContacts] = useState([]);
    useEffect(() => {
       fetch(`https://altair-admin.onrender.com/api/contact/1`)
          .then((response) => response.json())
          .then((data) => {
             setContacts(data);
          })
    }, []);

    const [footer, setFooter] = useState([]);
    useEffect(() => {
       fetch(`https://altair-admin.onrender.com/api/footer/1`)
          .then((response) => response.json())
          .then((data) => {
             setFooter(data);
          })
    }, []);

  const { pathname } = useLocation();
  if (pathname === "/login") return null;

  const getPlatform = () => {
    const userAgent = window.navigator.userAgent;

    if (userAgent.indexOf('Mac') !== -1) {
      return 'macOS';
    } else if (userAgent.indexOf('Windows') !== -1) {
      return 'Windows';
    } else if (userAgent.indexOf('Android') !== -1) {
      return 'Android';
    } else if (userAgent.indexOf('iOS') !== -1 || userAgent.indexOf('iPhone') !== -1 || userAgent.indexOf('iPad') !== -1) {
      return 'iOS';
    } else {
      return 'Unknown';
    }
  };

  const platform = getPlatform();

  let link;

  switch (platform) {
    case 'macOS':
      link = 'https://download.mql5.com/cdn/mobile/mt4/ios?server=AltairInc-Demo,AltairInc-Live';
      break;
    case 'iOS':
      link = 'https://download.mql5.com/cdn/mobile/mt4/ios?server=AltairInc-Demo,AltairInc-Live';
      break;
    case 'Windows':
      link = 'https://download.mql5.com/cdn/web/19536/mt4/altairinc4setup.exe';
      break;
    case 'Android':
      link = 'https://download.mql5.com/cdn/mobile/mt4/android?server=AltairInc-Demo,AltairInc-Live';
      break;
    default:
      link = 'https://download.mql5.com/cdn/web/19536/mt4/altairinc4setup.exe';
  }

  return (
    <div className='d-flex mt-auto '>
		  <footer className='w-100 d-lg-flex flex-column flex-lg-row justify-content-between home-desktop align-items-center'>
        <div className='footer-logo col-10 col-lg-2 flex-shrink-1 text-center'>
          <div className='footer-logo-inner h-100 d-lg-flex justify-content-center align-content-stretch h-100'>
           <Link to="./"><img alt="ALTAIR logo" className="logo" id="logo" src="/imgs/logo.svg" /></Link>
          </div>
        </div>

        <div className='footer-nav row'>
            <div className='d-flex flex-column justify-content-between footer-nav-col1'>
              <div>
                {/* <li><Link to={link}>{lang === 'en' ? 'Download MT4' : 'Descargar MT4'}</Link></li> */}
                <li><Link to="https://my.altairic.com/en/register">{lang === 'en' ? 'Register' : 'Registro'}</Link></li>
                <li><Link to="https://my.altairic.com/en/login">{lang === 'en' ? 'Withdrawal' : 'Retiro'}</Link></li>
                <li><Link to="/estatuto">{lang === 'en' ? 'AML & KYC Policies' : 'Políticas AML y KYC'}</Link></li>
                {/* <li><Link to="/faq">FAQs</Link></li> */}
              </div>

            </div>

            <div className='d-flex flex-column justify-content-between footer-nav-col2'>
              <div>
                <li><Link activeclassname="active" to={contact.instagram} target='_blank'>Instagram</Link></li>
                <li><Link activeclassname="active" to={contact.facebook} target='_blank'>Facebook</Link></li>
                <li><Link activeclassname="active" to={contact.linkedin} target='_blank'>Linked in</Link></li>
                <li>
                </li>
              </div>
            </div>

            <div className='d-flex flex-column justify-content-between footer-nav-col3'>
              <div>
                <li>{lang === 'en' ? 'Phone:' : 'Teléfono:'}<br/><Link activeclassname="active" to={'tel:' + contact.telephone}>{contact.telephone}</Link></li>
                <li>E-Mail:<br/><Link activeclassname="active" to={'mailto:' + contact.email}>{contact.email}</Link></li>
                <li>{lang === 'en' ? 'Business Hours:' : 'Horario:'}<br/>{lang === 'en' ? contact.time_eng : contact.time }</li>
              </div>
            </div>

            <div className='footer-nav-sm xxsmall' dangerouslySetInnerHTML={{ __html: lang === 'en' ? footer.content_eng : footer.content }} />


            <div className='d-flex flex-column justify-content-between footer-nav-col1'>
              <div className='footer-nav-sm xsmall'>© 2024 Altair Markets</div>
            </div>

            <div className='d-flex flex-column justify-content-between footer-nav-col2'>
              <div className='footer-nav-sm xsmall'><Link to="/privacy" className='xsmall'>{lang === 'en' ? 'Privacy Policy' : 'Política de Privacidad'}</Link></div>
            </div>

            <div className='d-flex flex-column justify-content-between footer-nav-col3'>
              <div className='footer-nav-sm d-flex justify-content-between xsmall'>
                <div><Link  to={`https://altairdev.s3.amazonaws.com/${lang === 'en' ? footer.privacy_eng : footer.privacy}`} className='xsmall'>{lang === 'en' ? 'Terms and Conditions' : 'Términos y condiciones'}</Link></div>
                <div className='xsmall'>{lang === 'en' ? 'Designed by:' : 'Diseño por:'} <Link to="https://hgestudio.com/" target='_blank' className='xsmall'>Hg Estudio</Link></div>

              </div>
            </div>

        </div>
      </footer>

      <footer className='w-100 home-mobile'>
        <div className='footer-logo-inner w-100 d-flex justify-content-center align-content-stretch'>
          <img alt="ALTAIR logo" className="logo" id="logo" src="/imgs/logo.svg" />
        </div>

        <div className='mt-40 d-flex justify-content-between'>
          <div className='col-6'>
            <p className='pb-11'><strong>{lang === 'en' ? 'Resources' : 'Recursos'}</strong></p>
            {/* <li><Link to={link}>{lang === 'en' ? 'Download MT4' : 'Descargar MT4'}</Link></li> */}
            <li><Link to="https://my.altairic.com/en/register">{lang === 'en' ? 'Register' : 'Registro'}</Link></li>
            <li><Link to="https://my.altairic.com/en/login">{lang === 'en' ? 'Withdrawal' : 'Retiro'}</Link></li>
            <li><Link to="/estatuto">{lang === 'en' ? 'AML & KYC Policies' : 'Políticas AML y KYC'}</Link></li>

            <div className='mt-40'>
              <p className='pb-11'><strong>RRSS</strong></p>
              <li><Link activeclassname="active" to={contact.instagram}  target='_blank'>Instagram</Link></li>
              <li><Link activeclassname="active" to={contact.facebook}  target='_blank'>Facebook</Link></li>
              <li><Link activeclassname="active" to={contact.linkedin}  target='_blank'>Linked in</Link></li>
            </div>
          </div>

          <div className='col-6 pl-20'>
            <p className='pb-11'><strong>{lang === 'en' ? 'Contact' : 'Contacto'}</strong></p>
            <p>{lang === 'en' ? 'Phone:' : 'Teléfono:'} <br/>
              <Link activeclassname="active" to={'tel:' + contact.telephone}>+52 55.2091.1938</Link>
            </p>

            <p>E-Mail: <br/>
            <Link activeclassname="active" to={'mailto:' + contact.email}>soporte@altairic<br/>.com</Link></p>

            <p>{lang === 'en' ? 'Business Hours:' : 'Horario:'}<br/>
            {lang === 'en' ? contact.time_eng : contact.time }</p>

            <div className='mt-40'>
              <p className='pb-11'><strong>Legal</strong></p>
              <li><Link to="/privacy">{lang === 'en' ? 'Privacy Policy' : 'Política de Privacidad'}</Link></li>
              <li><Link  to={`https://altairdev.s3.amazonaws.com/${lang === 'en' ? footer.privacy_eng : footer.privacy}`}>{lang === 'en' ? 'Terms and Conditions' : 'Términos y condiciones'}</Link></li>
            </div>

          </div>
        </div>

        <div className='footer-nav-sm xxsmall' dangerouslySetInnerHTML={{ __html: lang === 'en' ? footer.content_eng : footer.content }} />

        <div className='footer-nav-sm d-flex justify-content-between'>
                <div>© 2023 Altair Markets</div>
                <div>{lang === 'en' ? 'Designed by:' : 'Diseño por:'} <Link to="https://hgestudio.com/" target='_blank' className='credit'>Hg Estudio</Link></div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
