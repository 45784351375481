import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Contactf from './Contactf';
import Map from './Map';
import axios from 'axios';


function Contact({lang}) {
    const [formData, setFormData] = useState({ name: '', email: '', phone: '', message: '' });

    const [contact, setContacts] = useState([]);
    useEffect(() => {
       fetch(`https://altair-admin.onrender.com/api/contact/1`)
          .then((response) => response.json())
          .then((data) => {
             setContacts(data);
          })
    }, []);

    const handleChange = (event) => {
      const { name, value } = event.target;
      setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };
  
    const handleSubmit = async (event) => {
      event.preventDefault();
  
      try {
        // Make an API request to your backend
        const response = await axios.post('http://localhost:3001/send-email', formData);
  
        // Handle the response as needed
        console.log(response.data);
  
        // You can also reset the form data if needed
        setFormData({ name: '', email: '', phone: '', message: '' });
      } catch (error) {
        console.error('Error submitting form', error);
      }
    };

    return <div>
        <div id='contact-intro' className='contact-intro d-none d-md-block'>
            <div className='col-12 col-md-5 col-lg-5 offset-lg-1'>
                <h3>{lang === 'en' ? contact.title_eng : contact.title}</h3>
            </div>
        </div>

        <div className='col-12 col-md-5 col-lg-5 offset-md-1 offset-lg-0 d-md-flex flex-column align-items-end d-block d-md-none mt-96'>
                    <div className='d-md-flex w-100 justify-content-between contact-intro-inner'>
                        <div>
                            <div className='mb-28 mb-lg-40'>
                                <h3>{lang === 'en' ? 'Phone:' : 'Teléfono:'}</h3>
                                <p className='small'>{contact.telephone}</p>
                            </div>

                            <div className='mb-28 mb-lg-40'>
                                <h3>E-Mail:</h3>
                                <Link to={'mailto:' + contact.email}><p className='small'>{contact.email}</p></Link>
                            </div>

                            <div className='mb-28 mb-lg-40'>
                                <h3>{lang === 'en' ? 'Address:' : 'Dirección:'}</h3>
                                <p className='small' dangerouslySetInnerHTML={{ __html: contact.address }} />

                            </div>

                            <div>
                                <h3>{lang === 'en' ? 'Business Hours:' : 'Horario:'}</h3>
                                <p className='small mb-lg-0' dangerouslySetInnerHTML={{ __html: lang === 'en' ? contact.time_eng : contact.time }} />
                            </div>
                        </div>

                        <div className='h-100 d-flex flex-row-reverse flex-md-column justify-content-between align-items-center'>
                            <div className="contact-intro-svg" id="contact-svg" >
                                <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M99.0723 0.396484C71.7109 0.414222 49.5356 22.6004 49.5356 49.966C49.5356 77.3315 71.7109 99.5177 99.0723 99.5355V0.396484Z" fill="#FCC6E9"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M49.5367 0.396484C22.1753 0.414222 0 22.6004 0 49.966C0 77.3315 22.1753 99.5177 49.5367 99.5355V0.396484Z" fill="#467DD2"/>
                                </svg>
                            </div>

                            <div className='contact-socials align-self-end'>
                                <li><Link to={contact.instagram} target='_blank'>Instagram</Link></li>
                                <li><Link to={contact.facebook} target='_blank'>Facebook</Link></li>
                                <li><Link to={contact.linkedin} target='_blank'>Linked in</Link></li>
                            </div>
                        </div>
                    </div>
                </div>

        <form onSubmit={handleSubmit}>
            <div id='contact-intro' className='d-md-flex'>
                <div className='col-12 col-md-5 col-lg-5 offset-lg-1 mt-80 mt-md-0'>
                    <h2 className='d-block d-md-none'>{lang === 'en' ? contact.title_eng : contact.title}</h2>
                    <div className='home-contact-form col-lg-8'>
                        <div className='form-input'><input type="text" name='name' placeholder={lang === 'en' ? 'Name*' : 'Nombre*'}  value={formData.name} onChange={handleChange} /></div>
                        <div className='form-input'><input name='email' placeholder={lang === 'en' ? 'E-Mail*' : 'E-Mail*'} value={formData.email} onChange={handleChange}/></div>
                        <div className='form-input'><input name='phone' placeholder={lang === 'en' ? 'Phone (optional)' : 'Teléfono (opcional)'}  value={formData.phone} onChange={handleChange}/></div>
                        <div className='form-input'><textarea name='message' placeholder={lang === 'en' ? 'Message' : 'Mensaje'} rows="4" value={formData.message} onChange={handleChange}/></div>
                    </div>
                </div>

                <div className='col-12 col-md-5 col-lg-5 offset-md-1 offset-lg-0 d-md-flex flex-column align-items-end d-none d-md-block'>
                    <div className='d-md-flex w-100 justify-content-between contact-intro-inner'>
                        <div>
                            <div className='mb-lg-40'>
                                <h3>{lang === 'en' ? 'Phone:' : 'Teléfono:'}</h3>
                                <p className='small'>{contact.telephone}</p>
                            </div>

                            <div className='mb-lg-40'>
                                <h3>E-Mail:</h3>
                                <Link to="mailto:soporte@altairic.com"><p className='small'>{contact.email}</p></Link>
                            </div>

                            <div className='mb-lg-40'>
                                <h3>{lang === 'en' ? 'Address:' : 'Dirección:'}</h3>
                                <p className='small' dangerouslySetInnerHTML={{ __html: contact.address }} />

                            </div>

                            <div>
                                <h3>{lang === 'en' ? 'Business Hours:' : 'Horario:'}</h3>
                                <p className='small mb-lg-0' dangerouslySetInnerHTML={{ __html: lang === 'en' ? contact.time_eng : contact.time }} />
                            </div>
                        </div>

                        <div className='h-100 d-flex flex-column justify-content-between'>
                            <div className="contact-intro-svg home-desktop" id="contact-svg" >
                                <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M99.0723 0.396484C71.7109 0.414222 49.5356 22.6004 49.5356 49.966C49.5356 77.3315 71.7109 99.5177 99.0723 99.5355V0.396484Z" fill="#FCC6E9"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M49.5367 0.396484C22.1753 0.414222 0 22.6004 0 49.966C0 77.3315 22.1753 99.5177 49.5367 99.5355V0.396484Z" fill="#467DD2"/>
                                </svg>
                            </div>

                            <div className='contact-socials align-self-end'>
                                <li><Link to={contact.instagram} target='_blank'>Instagram</Link></li>
                                <li><Link to={contact.facebook} target='_blank'>Facebook</Link></li>
                                <li><Link to={contact.linkedin} target='_blank'>Linked in</Link></li>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col-12 col-md-5 col-lg-5 offset-lg-1'>
                <div className='col-12 col-md-12 col-lg-8'>
                    <div className='text-right h-100'>
                        <span className='xsmall'> {lang === 'en' ? '*Required fields' : '*Campos indispensables'}</span>
                    </div>

                    <div>
                        <button className='btn-principal' type='submit'>{lang === 'en' ? 'Send' : 'Enviar'}</button>
                    </div>
                </div>
            </div>
        </form>

        <div id='contact-map' className='contact-map col-12 col-xxl-8'>
            <Map />
        </div>
    </div>;
}

export default Contact;