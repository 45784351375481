import React, {useEffect, useState} from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link as ScrollLink, Element } from 'react-scroll';


function Brandbook({lang}) {
    const [brandbook, setBrandbook] = useState([]);
    useEffect(() => {
       fetch(`https://altair-admin.onrender.com/api/brandbook/1`)
          .then((response) => response.json())
          .then((data) => {
             setBrandbook(data);
          })
    }, []);


    return <div>
        <div className='d-block d-md-none mt-120'>
            <Dropdown autoClose="true">
                <Dropdown.Toggle id="dropdown-autoclose-outside">
                    Brandbook
                </Dropdown.Toggle>

                <Dropdown.Menu style={{ position: 'relative' }}>
                    <Dropdown.Item><ScrollLink to="somos">{lang === 'en' ? brandbook.about_eng : brandbook.about}</ScrollLink></Dropdown.Item>
                    <Dropdown.Item><ScrollLink to="concepto">{lang === 'en' ? brandbook.branding_title_eng : brandbook.branding_title}</ScrollLink></Dropdown.Item>
                    <Dropdown.Item><ScrollLink to="logo">{lang === 'en' ? brandbook.lnf_logo_eng : brandbook.lnf_logo}</ScrollLink></Dropdown.Item>
                    <Dropdown.Item><ScrollLink to="usos">{lang === 'en' ? brandbook.lnf_incorrectos_eng : brandbook.lnf_incorrectos}</ScrollLink></Dropdown.Item>
                    <Dropdown.Item><ScrollLink to="color">{lang === 'en' ? brandbook.color_title_eng : brandbook.color_title}</ScrollLink></Dropdown.Item>
                    <Dropdown.Item><ScrollLink to="tipografia">{lang === 'en' ? brandbook.type_title_eng : brandbook.type_title}</ScrollLink></Dropdown.Item>
                    <Dropdown.Item><ScrollLink to="recursos">{lang === 'en' ? brandbook.resources_title_eng : brandbook.resources_title}</ScrollLink></Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
        
        <div id='brandbook' className='brandbook row col-12 h-100'>
            <div className='col-4 col-lg-2 flex-column justify-content-end brandbook-sidebar d-none d-md-flex sticky-top'>
                <li><ScrollLink to="somos" spy={true} smooth={true} offset={-70} duration={500} activeClass="active" className='cursor-pointer'>{lang === 'en' ? brandbook.about_eng : brandbook.about}</ScrollLink></li>
                <li><ScrollLink to="concepto" spy={true} smooth={true} offset={-70} duration={500} activeClass="active" className='cursor-pointer'>{lang === 'en' ? brandbook.branding_title_eng : brandbook.branding_title}</ScrollLink></li>
                <li><ScrollLink to="logo" spy={true} smooth={true} offset={-70} duration={500} activeClass="active" className='cursor-pointer'>{lang === 'en' ? brandbook.lnf_logo_eng : brandbook.lnf_logo}</ScrollLink></li>
                <li><ScrollLink to="usos" spy={true} smooth={true} offset={-70} duration={500} activeClass="active" className='cursor-pointer'>{lang === 'en' ? brandbook.lnf_incorrectos_eng : brandbook.lnf_incorrectos}</ScrollLink></li>
                <li><ScrollLink to="color" spy={true} smooth={true} offset={-70} duration={500} activeClass="active" className='cursor-pointer'>{lang === 'en' ? brandbook.color_title_eng : brandbook.color_title}</ScrollLink></li>
                <li><ScrollLink to="tipografia" spy={true} smooth={true} offset={-70} duration={500} activeClass="active" className='cursor-pointer'>{lang === 'en' ? brandbook.type_title_eng : brandbook.type_title}</ScrollLink></li>
                <li><ScrollLink to="recursos" spy={true} smooth={true} offset={-70} duration={500} activeClass="active" className='cursor-pointer'>{lang === 'en' ? brandbook.resources_title_eng : brandbook.resources_title}</ScrollLink></li>
            </div>

            <div className='brandbook-content offset-md-1 col-12 col-md-7'>
            
                <h3>{lang === 'en' ? brandbook.title_eng : brandbook.title}</h3>
                <div className='small-brandbook'>
                    <p className='small' dangerouslySetInnerHTML={{ __html: lang === 'en' ? brandbook.desc_eng : brandbook.desc }} />
                </div>


                <Element name="somos">
                    <div id="somos" className='mt-30'>
                        <h5>{lang === 'en' ? brandbook.about_eng : brandbook.about}</h5>
                        <div className='small-brandbook mt-30'>
                            <p className='small' dangerouslySetInnerHTML={{ __html: lang === 'en' ? brandbook.about_desc_eng : brandbook.about_desc }} />
                        </div>
 
                        <div className='col-lg-4'>
                            <img alt="Mision" className="brandbook-img vision" id="mision" src={`https://altairdev.s3.amazonaws.com/${brandbook.about_img}`} />
                        </div>
                        
                        <h4 className='mt-50'>{lang === 'en' ? brandbook.mision_eng : brandbook.mision}</h4>
                        <div className='small-brandbook mt-30'>
                            <p className='small' dangerouslySetInnerHTML={{ __html: lang === 'en' ? brandbook.mision_txt_eng : brandbook.mision_txt }} />
                        </div>

                        <h4 className='mt-20 mt-30'>{lang === 'en' ? brandbook.vision_eng : brandbook.vision}</h4>

                        <div className='small-brandbook mt-30'>
                        <p className='small' dangerouslySetInnerHTML={{ __html: lang === 'en' ? brandbook.vision_txt_eng : brandbook.vision_txt }} />
                        </div>

                        <h4 className='mt-20 mt-30'>{lang === 'en' ? 'Values': 'Valores'}</h4>
                        <p className='small'>{lang === 'en' ? brandbook.valores_eng : brandbook.valores}</p>

                        <div className='mt-30 col-12 col-md-7'>
                            <img alt="Slogan" className="brandbook-img" id="mision" src={`https://altairdev.s3.amazonaws.com/${brandbook.valores_img}`} />
                        </div>

                        <h4 className='mt-50'>{lang === 'en' ? brandbook.slogan_eng : brandbook.slogan}</h4>
                        <div className='small-brandbook mt-30'>
                            <p className='small' dangerouslySetInnerHTML={{ __html: lang === 'en' ? brandbook.slogan_txt_eng : brandbook.slogan_txt }} />
                        </div>

                    </div>
                </Element>

                <Element name="concepto">
                <div id="concepto" className='mt-50'>
                    <h3>{lang === 'en' ? brandbook.branding_title_eng : brandbook.branding_title}</h3>

                    <div className='small-brandbook mt-30'>
                        <p className='mt-50'>{lang === 'en' ? brandbook.branding_subtitle_eng : brandbook.branding_subtitle}</p>
                    </div>

                    <div className='small-brandbook mt-30'>
                        <p className='mt-50' dangerouslySetInnerHTML={{ __html: lang === 'en' ? brandbook.branding_text_eng : brandbook.branding_text }} />
                    </div>

                    <div className='col-12 col-md-7'>
                        <img alt="Concepto" className="brandbook-img" id="concepto" src={`https://altairdev.s3.amazonaws.com/${brandbook.branding_img}`} />
                    </div>

                    <div className='small-brandbook mt-30'>
                        <p className='small' dangerouslySetInnerHTML={{ __html: lang === 'en' ? brandbook.branding_text_2_eng : brandbook.branding_text_2 }} />
                    </div>


                    <h4 className='mt-30'>{lang === 'en' ? brandbook.branding_subtitle_3_eng : brandbook.branding_subtitle_3}</h4>

                    <div className='small-brandbook mt-30'>
                        <p className='small mt-30' dangerouslySetInnerHTML={{ __html: lang === 'en' ? brandbook.branding_text_3_eng : brandbook.branding_text_3 }} />
                    </div>


                    <h4 className='mt-50'>{lang === 'en' ? brandbook.lnf_title_eng : brandbook.lnf_title}</h4>

                    <div className='col-11'>
                        <img alt="Look and Feel" className="brandbook-img look" id="Look and Feel" src={`https://altairdev.s3.amazonaws.com/${lang === 'en' ? brandbook.lnf_img_eng : brandbook.lnf_img}`} />
                    </div>
                </div>
                </Element>

                <Element name="logo">
                    <div id="logo">

                        <p className='mt-50'>{lang === 'en' ? brandbook.lnf_logo_eng : brandbook.lnf_logo}</p>
                        <div className='small-brandbook mt-30'>
                            <p className='small mt-30' dangerouslySetInnerHTML={{ __html: lang === 'en' ? brandbook.lnf_logo_txt_eng : brandbook.lnf_logo_txt }} />
                        </div>

                        <div className='col-12 col-md-7'>
                            <img alt="Logotipo" className="brandbook-img" id="Logotipo" src={`https://altairdev.s3.amazonaws.com/${brandbook.lnf_logo_img}`} />
                        </div>

                        <p className='mt-50'>{lang === 'en' ? brandbook.lnf_simbolo_eng : brandbook.lnf_simbolo}</p>

                        <div className='small-brandbook mt-30'>
                            <p className='small mt-30' dangerouslySetInnerHTML={{ __html: lang === 'en' ? brandbook.lnf_simbolo_txt_eng : brandbook.lnf_simbolo_txt }} />
                        </div>
                        
                        <div className='col-12 col-md-7'>
                            <img alt="Simbolo" className="brandbook-img" id="Simbolo" src={`https://altairdev.s3.amazonaws.com/${brandbook.lnf_simbolo_img}`} />
                        </div>

                        <h4 className='mt-50'>{lang === 'en' ? brandbook.lnf_reticula_eng : brandbook.lnf_reticula}</h4>
                        <div className='small-brandbook mt-30'>
                            <p className='small mt-30' dangerouslySetInnerHTML={{ __html: lang === 'en' ? brandbook.lnf_reticula_txt_eng : brandbook.lnf_reticula_txt}} />
                        </div>

                        <div className='col-12 col-md-6'>
                            <img alt="Reticula" className="brandbook-img look" id="Reticula" src={`https://altairdev.s3.amazonaws.com/${brandbook.lnf_reticula_img}`} />
                        </div>

                        <p className='mt-10 xsmall'>X = 1</p>

                        <p className='mt-50'>{lang === 'en' ? brandbook.lnf_proteccion_eng : brandbook.lnf_proteccion}</p>
                        <div className='small-brandbook mt-30'>
                            <p className='small mt-30' dangerouslySetInnerHTML={{ __html: lang === 'en' ? brandbook.lnf_proteccion_txt_eng : brandbook.lnf_proteccion_txt}} />
                        </div>


                        <div className='col-12 col-md-6'>
                            <img alt="Proteccion" className="brandbook-img look" id="Proteccion" src={`https://altairdev.s3.amazonaws.com/${brandbook.lnf_proteccion_img}`} />
                        </div>

                        <p className='mt-50'>{lang === 'en' ? brandbook.lnf_minimos_eng : brandbook.lnf_minimos}</p>
                        <div className='small-brandbook mt-30'>
                            <p className='small mt-30' dangerouslySetInnerHTML={{ __html: lang === 'en' ? brandbook.lnf_minimos_txt_eng : brandbook.lnf_minimos_txt}} />
                        </div>

                        <div className='col-12 col-md-7'>
                            <img alt="Proteccion" className="brandbook-img look" id="Proteccion" src={`https://altairdev.s3.amazonaws.com/${lang === 'en' ? brandbook.lnf_minimos_img_eng : brandbook.lnf_minimos_img}`} />
                        </div>
                    </div>
                </Element>

                <Element name="usos">
                    <div id='usos'>
                        <p className='mt-50'>{lang === 'en' ? brandbook.lnf_incorrectos_eng : brandbook.lnf_incorrectos}</p>

                        <div className='small-brandbook mt-30'>
                            <p className='small mt-30' dangerouslySetInnerHTML={{ __html: lang === 'en' ? brandbook.lnf_incorrectos_txt_eng : brandbook.lnf_incorrectos_txt}} />
                        </div>

                        <div className='d-flex h-100 align-items-end'>
                            <div className='col-5 col-md-2'>
                                <img alt="Proteccion" className="brandbook-img" id="Proteccion" src={`https://altairdev.s3.amazonaws.com/${brandbook.lnf_incorrectos_img_a}`} />
                            </div>
                            <div className='pl-10 pl-md-30 col-7 col-md-10'>
                                <div className='small-brandbook'>
                                    <p className='small mt-30' dangerouslySetInnerHTML={{ __html: lang === 'en' ? brandbook.lnf_incorrectos_desc_a_eng : brandbook.lnf_incorrectos_desc_a}} />
                                </div>
                            </div>
                        </div>

                        <div className='mt-10 d-flex h-100 align-items-end'>
                            <div className='col-5 col-md-2'>
                                <img alt="Proteccion" className="brandbook-img" id="Proteccion" src={`https://altairdev.s3.amazonaws.com/${brandbook.lnf_incorrectos_img_b}`} />
                            </div>
                            <div className='pl-10 pl-md-30 col-7 col-md-10'>
                                <div className='small-brandbook'>
                                    <p className='small mt-30' dangerouslySetInnerHTML={{ __html: lang === 'en' ? brandbook.lnf_incorrectos_desc_b_eng : brandbook.lnf_incorrectos_desc_b}} />
                                </div>
                            </div>
                        </div>

                        <div className='mt-10 d-flex h-100 align-items-end'>
                            <div className='col-5 col-md-2'>
                                <img alt="Proteccion" className="brandbook-img" id="Proteccion" src={`https://altairdev.s3.amazonaws.com/${brandbook.lnf_incorrectos_img_c}`} />
                            </div>
                            <div className='pl-10 pl-md-30 col-7 col-md-10'>
                                <div className='small-brandbook'>
                                    <p className='small mt-30' dangerouslySetInnerHTML={{ __html: lang === 'en' ? brandbook.lnf_incorrectos_desc_c_eng : brandbook.lnf_incorrectos_desc_c}} />
                                </div>
                            </div>
                        </div>

                        <div className='mt-10 d-flex h-100 align-items-end'>
                            <div className='col-5 col-md-2'>
                                <img alt="Proteccion" className="brandbook-img" id="Proteccion" src={`https://altairdev.s3.amazonaws.com/${brandbook.lnf_incorrectos_img_d}`} />
                            </div>
                            <div className='pl-10 pl-md-30 col-7 col-md-10'>
                                <div className='small-brandbook'>
                                    <p className='small mt-30' dangerouslySetInnerHTML={{ __html: lang === 'en' ? brandbook.lnf_incorrectos_desc_d_eng : brandbook.lnf_incorrectos_desc_d}} />
                                </div>
                            </div>
                        </div>

                        <div className='mt-10 d-flex h-100 align-items-end'>
                            <div className='col-5 col-md-2'>
                                <img alt="Proteccion" className="brandbook-img" id="Proteccion" src={`https://altairdev.s3.amazonaws.com/${brandbook.lnf_incorrectos_img_e}`} />
                            </div>
                            <div className='pl-10 pl-md-30 col-7 col-md-10'>
                                <div className='small-brandbook'>
                                    <p className='small mt-30' dangerouslySetInnerHTML={{ __html: lang === 'en' ? brandbook.lnf_incorrectos_desc_e_eng : brandbook.lnf_incorrectos_desc_e}} />
                                </div>
                            </div>
                        </div>

                        <div className='mt-10 d-flex h-100 align-items-end'>
                            <div className='col-5 col-md-2'>
                                <img alt="Proteccion" className="brandbook-img" id="Proteccion" src={`https://altairdev.s3.amazonaws.com/${brandbook.lnf_incorrectos_img_f}`}/>
                            </div>
                            <div className='pl-10 pl-md-30 col-7 col-md-10'>
                                <div className='small-brandbook'>
                                    <p className='small mt-30' dangerouslySetInnerHTML={{ __html: lang === 'en' ? brandbook.lnf_incorrectos_desc_f_eng : brandbook.lnf_incorrectos_desc_f}} />
                                </div>
                            </div>
                        </div>

                        <div className='mt-10 d-flex h-100 align-items-end'>
                            <div className='col-5 col-md-2'>
                                <img alt="Proteccion" className="brandbook-img" id="Proteccion" src={`https://altairdev.s3.amazonaws.com/${brandbook.lnf_incorrectos_img_g}`} />
                            </div>
                            <div className='pl-10 pl-md-30 col-7 col-md-10'>
                                <div className='small-brandbook'>
                                    <p className='small mt-30' dangerouslySetInnerHTML={{ __html: lang === 'en' ? brandbook.lnf_incorrectos_desc_g_eng : brandbook.lnf_incorrectos_desc_g}} />
                                </div>
                            </div>
                        </div>

                        <div className='mt-10 d-flex h-100 align-items-end'>
                            <div className='col-5 col-md-2'>
                                <img alt="Proteccion" className="brandbook-img" id="Proteccion" src={`https://altairdev.s3.amazonaws.com/${brandbook.lnf_incorrectos_img_h}`} />
                            </div>
                            <div className='pl-10 pl-md-30 col-7 col-md-10'>
                                <div className='small-brandbook'>
                                    <p className='small mt-30' dangerouslySetInnerHTML={{ __html: lang === 'en' ? brandbook.lnf_incorrectos_desc_h_eng : brandbook.lnf_incorrectos_desc_h}} />
                                </div>
                            </div>
                        </div>

                        <div className='mt-10 d-flex h-100 align-items-end'>
                            <div className='col-5 col-md-2'>
                                <img alt="Proteccion" className="brandbook-img" id="Proteccion" src={`https://altairdev.s3.amazonaws.com/${brandbook.lnf_incorrectos_img_i}`} />
                            </div>
                            <div className='pl-10 pl-md-30 col-7 col-md-10'>
                                <div className='small-brandbook'>
                                    <p className='small mt-30' dangerouslySetInnerHTML={{ __html: lang === 'en' ? brandbook.lnf_incorrectos_desc_i_eng : brandbook.lnf_incorrectos_desc_i}} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Element>

                <Element name="color">
                    <div id='color'>
                        <h4 className='mt-50'>{lang === 'en' ? brandbook.color_title_eng : brandbook.color_title}</h4>
                        <div className='small-brandbook mt-30'>
                            <p className='small mt-30' dangerouslySetInnerHTML={{ __html: lang === 'en' ? brandbook.color_txt_eng : brandbook.color_txt}} />
                        </div>

                        <div className='col-12 col-md-7'>
                            <img alt="Paleta de color" className="brandbook-img" id="Paleta de color" src={`https://altairdev.s3.amazonaws.com/${brandbook.color_img_a}`} />
                        </div>

                        <div className='col-12 col-md-7'>
                            <img alt="Paleta de color" className="brandbook-img look" id="Proteccion" src={`https://altairdev.s3.amazonaws.com/${brandbook.color_img_b}`} />
                        </div>

                        <p className='mt-50'>{lang === 'en' ? brandbook.color_usos_title_eng : brandbook.color_usos_title}</p>
                        <div className='small-brandbook mt-30'>
                            <p className='small mt-30' dangerouslySetInnerHTML={{ __html: lang === 'en' ? brandbook.color_usos_txt_eng : brandbook.color_usos_txt}} />
                        </div>

                        <div className='row col-12 mb-100'>
                            <div className='col-6 col-md-5'><img alt="Logo light" className="brandbook-img" src={`https://altairdev.s3.amazonaws.com/${brandbook.color_usos_img_a}`} /></div>
                            <div className='col-6 col-md-5'><img alt="Logo dark" className="brandbook-img" src={`https://altairdev.s3.amazonaws.com/${brandbook.color_usos_img_b}`} /></div>
                        </div>

                        <p className='mt-50'>{lang === 'en' ? brandbook.color_simbolo_title_eng : brandbook.color_simbolo_title}</p>
                        <div className='small-brandbook mt-30'>
                            <p className='small mt-30' dangerouslySetInnerHTML={{ __html: lang === 'en' ? brandbook.color_simbolo_txt_eng : brandbook.color_simbolo_txt}} />
                        </div>


                        <div className='col-12 col-md-7'>
                            <img alt="Símbolos" className="brandbook-img look" id="Símbolos" src={`https://altairdev.s3.amazonaws.com/${brandbook.color_simbolo_img}`} />
                        </div>

                        <p className='mt-50'>{lang === 'en' ? brandbook.color_positiven_title_eng : brandbook.color_positiven_title}</p>
                        <div className='small-brandbook mt-30'>
                            <p className='small mt-30' dangerouslySetInnerHTML={{ __html: lang === 'en' ? brandbook.color_positiven_txt_eng : brandbook.color_positiven_txt}} />
                        </div>

                        
                        <div className='row col-12 mb-100'>
                            <div className='col-6 col-md-5'><img alt="Logo light" className="brandbook-img" src={`https://altairdev.s3.amazonaws.com/${brandbook.color_positiven_img_a}`} /></div>
                            <div className='col-6 col-md-5'><img alt="Logo dark" className="brandbook-img" src={`https://altairdev.s3.amazonaws.com/${brandbook.color_positiven_img_b}`} /></div>
                        </div>

                        <p className='mt-50'>{lang === 'en' ? brandbook.color_app_title_eng : brandbook.color_app_title}</p>
                        <div className='small-brandbook mt-30'>
                            <p className='small mt-30' dangerouslySetInnerHTML={{ __html: lang === 'en' ? brandbook.color_app_txt_eng : brandbook.color_app_txt}} />
                        </div>


                        <div className='row col-12 mb-50'>
                            <div className='col-6 col-md-5'><img alt="Logo" className="brandbook-img" src={`https://altairdev.s3.amazonaws.com/${brandbook.color_app_img_a}`} /></div>
                            <div className='col-6 col-md-5'><img alt="Logo" className="brandbook-img" src={`https://altairdev.s3.amazonaws.com/${brandbook.color_app_img_b}`} /></div>
                        </div>

                        <div className='row col-12 mb-50'>
                            <div className='col-6 col-md-5'><img alt="Logo" className="brandbook-img" src={`https://altairdev.s3.amazonaws.com/${brandbook.color_app_img_c}`} /></div>
                            <div className='col-6 col-md-5'><img alt="Logo" className="brandbook-img" src={`https://altairdev.s3.amazonaws.com/${brandbook.color_app_img_d}`} /></div>
                        </div>

                        <div className='row col-12 mb-100'>
                            <div className='col-6 col-md-5'><img alt="Logo" className="brandbook-img" src={`https://altairdev.s3.amazonaws.com/${brandbook.color_app_img_e}`} /></div>
                            <div className='col-6 col-md-5'><img alt="Logo" className="brandbook-img" src={`https://altairdev.s3.amazonaws.com/${brandbook.color_app_img_f}`} /></div>
                        </div>
                    </div>
                </Element>

                <Element name="tipografia">
                    <div id='tipografia'>
                        <h4 className='mt-50'>{lang === 'en' ? brandbook.type_title_eng : brandbook.type_title}</h4>
                        <div className='small-brandbook mt-30'>
                            <p className='small mt-30' dangerouslySetInnerHTML={{ __html: lang === 'en' ? brandbook.type_txt_eng : brandbook.type_txt}} />
                        </div>


                        <p className='mt-30'>{lang === 'en' ? brandbook.type_subtitle_eng : brandbook.type_subtitle}</p>

                        <div className='col-12 col-md-7'>
                            <img alt="Tipgorafia" className="brandbook-img" src={`https://altairdev.s3.amazonaws.com/${brandbook.type_img_a}`} />
                        </div>

                        <div className='mt-30 col-12 col-md-7'>
                            <img alt="Tipgorafia" className="brandbook-img" src={`https://altairdev.s3.amazonaws.com/${brandbook.type_img_b}`} />
                        </div>

                        <div className='mt-30 col-12 col-md-7'>
                            <img alt="Tipgorafia" className="brandbook-img" src={`https://altairdev.s3.amazonaws.com/${brandbook.type_img_c}`} />
                        </div>
                    </div>
                </Element>

                <Element name="recursos">
                    <div id='recursos'>
                        <h4 className='mt-60'>{lang === 'en' ? brandbook.resources_title_eng : brandbook.resources_title}</h4>
                        <div className='small-brandbook mt-30'>
                            <p className='small mt-30' dangerouslySetInnerHTML={{ __html: lang === 'en' ? brandbook.resources_txt_eng : brandbook.resources_txt}} />
                        </div>

                    
                        <div className='col-12 col-md-7'>
                            <img alt="Grafico" className="brandbook-img look" src={`https://altairdev.s3.amazonaws.com/${brandbook.resources_img_a}`} />
                        </div>

                        <div className='mt-50 col-12 col-md-7'>
                            <img alt="Grafico" className="brandbook-img look" src={`https://altairdev.s3.amazonaws.com/${brandbook.resources_img_b}`} />
                        </div>

                        <div className='mt-50 col-12 col-md-7'>
                            <img alt="Grafico" className="brandbook-img look" src={`https://altairdev.s3.amazonaws.com/${brandbook.resources_img_c}`} />
                        </div>

                        <div className='mt-50 col-12 col-md-7'>
                            <img alt="Grafico" className="brandbook-img look" src={`https://altairdev.s3.amazonaws.com/${brandbook.resources_img_d}`} />
                        </div>

                        <div className='mt-50 col-12 col-md-7'>
                            <img alt="Grafico" className="brandbook-img look" src={`https://altairdev.s3.amazonaws.com/${brandbook.resources_img_e}`} />
                        </div>

                        <div className='mt-50 col-12 col-md-7'>
                            <img alt="Grafico" className="brandbook-img look" src={`https://altairdev.s3.amazonaws.com/${brandbook.resources_img_f}`} />
                        </div>

                        <p className='mt-50'>{lang === 'en' ? brandbook.icons_title_eng : brandbook.icons_title}</p>
                        <div className='small-brandbook mt-30'>
                            <p className='small mt-30' dangerouslySetInnerHTML={{ __html: lang === 'en' ? brandbook.icons_txt_eng : brandbook.icons_txt}} />
                        </div>

                        <div className='col-12 col-md-7'>
                            <img alt="Iconos" className="brandbook-img look" src={`https://altairdev.s3.amazonaws.com/${brandbook.icons_img_a}`} />
                        </div>
                    </div>
                </Element>
            </div>
        </div>
    </div>;
}

export default Brandbook;