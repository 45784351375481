import React, {useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Contactf from './Contactf';


function Estrategias({lang}) {
    const [estrategias, setEstrategias] = useState([]);
    useEffect(() => {
       fetch(`https://altair-admin.onrender.com/api/estrategias/1`)
          .then((response) => response.json())
          .then((data) => {
             setEstrategias(data);
          })
    }, []);

    return <div>
        <div id='about-hero' className='estrategias-hero'>
            <figure>
                <img alt="Estrategias" className="estrategias-hero-img" id="about-img"  src={`https://altairdev.s3.amazonaws.com/${estrategias.hero}`} />
            </figure>
        </div>

        <div id='estrategias-intro' className='d-md-flex align-items-end estrategias-intro'>
            <div className='col-11 col-md-8 col-lg-7 offset-lg-1'>
                <h2>{lang === 'en' ? estrategias.title_eng : estrategias.title}</h2>
                <p className='about-txt' dangerouslySetInnerHTML={{ __html: lang === 'en' ? estrategias.content_eng : estrategias.content }} />

            </div>

            <div className='estrategias-svg offset-1 col-3 d-flex flex-column justify-content-end align-items-end d-none d-md-block'>
                <figure>
                    <img alt="Estrategia" className="estrategy-svg" id="about-svg" src="/imgs/strategy.svg" />
                </figure>
            </div>  
        </div>

        <div className='colg-8 col-lg-7 offset-lg-1'>
            <div className='estrategias-intro-btn'><button className='btn-secondary'>{lang === 'en' ? 'Contact us via WhatsApp' : 'Contacto por WhatsApp'}</button></div>
        </div>

        <div className='estrategias-svg mt-40 col-12 d-flex justify-content-center d-md-none'>
            <svg width="228" height="153" viewBox="0 0 228 153" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M151.798 -3.3309e-06L151.798 76.0824L151.917 76.0825C193.936 76.0825 228 42.0193 228 0L151.798 -3.3309e-06Z" fill="#467DD2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M227.94 76.078L151.858 76.078L151.857 76.1969C151.858 118.216 185.921 152.28 227.94 152.28L227.94 76.078Z" fill="#FCC6E9"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.81514e-05 76.078C2.71759e-05 76.1177 -3.32202e-06 76.1574 -3.32028e-06 76.1971C-1.48654e-06 118.148 34.0081 152.156 75.9592 152.156C117.91 152.156 151.918 118.148 151.918 76.1971C151.918 76.1574 151.918 76.1177 151.918 76.078L8.81514e-05 76.078Z" fill="#69FFAD"/>
            </svg>
        </div>  

        <form method="post">
            <div className='col-12 d-md-flex align-items-end estrategias-contact'>
                <div className='col-12 col-md-5 col-lg-3 offset-lg-1 home-contact-img'>
                    <figure>
                        <img alt="Contact" id="estrategias-contact-img" src={`https://altairdev.s3.amazonaws.com/${estrategias.contact_img}`} />
                    </figure>
                </div>
                <div className='col-12 offset-md-1 offset-lg-1 col-md-5 col-lg-6 home-contact-txt'>
                    <h3>{lang === 'en' ? estrategias.contact_title_eng : estrategias.contact_title}</h3>
                    <Contactf lang={lang} />
                </div>
            </div>
            <div className='offset-md-6 offset-lg-5 col-md-5 col-lg-6'>
                <div className='col-lg-8'>
                    <div className='text-right h-100'>
                        <span className='xsmall'> {lang === 'en' ? '*Required fields' : '*Campos indispensables'}</span>
                    </div>

                    <div>
                        <button className='btn-principal' type='submit'>{lang === 'en' ? 'Send' : 'Enviar'}</button>
                    </div>
                </div>
            </div>
        </form>

        <div id='estrategias-w' className='estrategias-w d-lg-flex align-items-end col-12 col-md-10'>
            <div className='estrategias-w-txt col-12 col-lg-6'>
                <h2>{estrategias.w_title}</h2>
                <p className='small' dangerouslySetInnerHTML={{ __html: lang === 'en' ? estrategias.w_content_eng : estrategias.w_content }} />
                
            </div>
            <div className='estrategias-w-img d-flex justify-content-center col-12 col-lg-6'>
                  <img alt="Estrategias" id="estrategias-img" src={`https://altairdev.s3.amazonaws.com/${estrategias.w_img}`} />
            </div>
        </div>
    </div>;
}

export default Estrategias;