import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Countup from './Countup';
import Values from './Values';



function About({lang}) {
    const [formData, setFormData] = useState({name: "",email: "",phone: "",message: ""});

    const [nosotros, setNosotros] = useState([]);
    useEffect(() => {
       fetch(`https://altair-admin.onrender.com/api/nosotros/1`)
          .then((response) => response.json())
          .then((data) => {
             setNosotros(data);
          })
    }, []);


    return <div>
        <div id='about-hero' className='about-hero'>
            <figure>
                <img alt="ALTAIR hero image" className="home-hero-img" id="about-img" src={`https://altairdev.s3.amazonaws.com/${nosotros.hero}`} />
            </figure>
        </div>

        <div id='about-intro' className='d-md-flex d-lg-flex align-items-end about-intro'>
            <div className='col-12 col-md-8 col-lg-7 offset-lg-1'>
                <h2 className='col-11'>{lang === 'en' ? nosotros.title_eng : nosotros.title}</h2>
                <p className='mt-15 col-11 col-xxl-8' dangerouslySetInnerHTML={{ __html: lang === 'en' ? nosotros.content_eng : nosotros.content }} />
            </div>


            <div className='about-img offset-md-1 offset-lg-0 col-12 col-md-3 d-flex justify-content-center justify-content-md-end'>
                <figure>
                    <svg viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M199.176 199.041C199.14 144.071 154.567 99.5195 99.5878 99.5195C44.6091 99.5195 0.0358409 144.071 2.61674e-05 199.041L199.176 199.041Z" fill="#001A64"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M199.176 99.5215C199.14 44.5511 154.567 -1.94992e-06 99.5878 -4.35312e-06C44.6091 -6.75631e-06 0.0358409 44.5511 2.61674e-05 99.5215L199.176 99.5215Z" fill="#69FFAD"/>
                    </svg>
                </figure>
            </div>  
        </div>

        <Values lang={lang}/>

        <div id='about-image' className='about-parallax d-flex justify-content-end'>
            <div className='col-lg-11'>
                <img alt="About hero" id="hero-img-full" src={`https://altairdev.s3.amazonaws.com/${nosotros.hero_2}`} />
            </div>
        </div>

        <Countup lang={lang} />

        <div id='about-brandbook' className='about-brandbook col-12 col-md-10 offset-md-1 text-center'>
            <div className='d-md-flex align-items-end justify-content-center'>
                <img alt="Brandbook" id="about-brandbook" src="/imgs/about_brandbook.svg" />
                <div className='about-brandbook-txt'><h3>{lang === 'en' ? nosotros.brandbook_eng : nosotros.brandbook}</h3></div>
            </div>
            <div className='about-brandbook-btn'><button className='btn-principal'><Link to="/brandbook">{lang === 'en' ? 'Go to Brandbook' : 'Ir a Brand book'}</Link></button></div>
        </div>
    </div>;
}

export default About;