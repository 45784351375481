import React, {useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Countup from './Countup';
import Quotes from './Quotes.js';
import Currency from './Currency';
import Values from './Values';



function Home({handleLanguageChange, lang}) {
    const [formData, setFormData] = useState({name: "",email: "",phone: "",message: ""});

    const [homes, setHome] = useState([]);
    useEffect(() => {
       fetch(`https://altair-admin.onrender.com/api/homes/1`)
          .then((response) => response.json())
          .then((data) => {
             setHome(data);
          })
    }, []);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        alert(`Name: ${formData.name}, Email: ${formData.email}, Phone: ${formData.phone}, Message: ${formData.message}`
        );
    };

    useEffect(() => {
        // Header scroll
        window.onscroll = function() {scrollFunction()};

        function scrollFunction() {
            var logopinned = document.getElementById("logo-pinned");
            var logoscroll = document.getElementById("logo-scroll");

            if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 200) {
                logopinned.classList.add("hide");
                logopinned.classList.remove("show")
                logoscroll.classList.add("show");
                logoscroll.classList.remove("hide")
            } else {
                logopinned.classList.add("show");
                logopinned.classList.remove("hide")
                logoscroll.classList.add("hide");
                logoscroll.classList.remove("show");
            }
        }
    }, []);

    return <div>
        <div id='#home-hero'>
            <div className='home-hero-content w-100 d-flex justify-content-between align-items-center'>
                <div className='home-hero-txt col-12 col-md-9 col-lg-9 col-xl-8 col-xxl-7'>
                    <div className='col-12 col-md-11 col-lg-10'>
                        <h1>{lang === 'en' ? homes.title_eng : homes.title}</h1>
                    </div>
                    <p className='mt-30 mt-lg-30' dangerouslySetInnerHTML={{ __html: lang === 'en' ? homes.content_eng : homes.content }} />
                </div>

                <div>
                    <img alt="ALTAIR hero" className="home-hero-svg" id="hero-svg" src="/imgs/home_hero.svg" />
                </div>

            </div>

            <div className='home-hero-img'>
                <figure>
                    <img alt="ALTAIR hero" className="home-hero-img" id="hero-img" src={`https://altairdev.s3.amazonaws.com/${homes.hero}`} loading='lazy'/>
                </figure>
            </div>
        </div>

        <div id='#home-about' className='home-about d-flex flex-column-reverse flex-md-row  align-items-lg-end'>
            <div className='home-about-img col-11 col-md-4 col-lg-3 offset-lg-1 d-none d-md-block'>
                <figure>
                    <img alt="About" className="home-about-img" id="home-about" src={`https://altairdev.s3.amazonaws.com/${homes.intro_image}`} loading='lazy' />
                </figure>
            </div>

            <div className='home-about-content col-12 col-md-7 col-lg-6 offset-md-1'>
                <h2 className='col-10 col-md-12'>{lang === 'en' ? homes.intro_heading_eng : homes.intro_heading}</h2>
                <p className='home-about-txt' dangerouslySetInnerHTML={{ __html: lang === 'en' ? homes.intro_content_eng : homes.intro_content }} />


            </div>
        </div>

        <div className='d-md-block'><Values lang={lang} /></div>

        <div id='about-image' className='about-parallax d-flex justify-content-end'>
            <div className='offset-lg-1 col-lg-11'>
                <img alt="About hero" id="hero-img-full" src={`https://altairdev.s3.amazonaws.com/${homes.estrategias_img}`} />
            </div>
        </div>

        <div id='#home-estrategias' className='justify-content-center home-estrategia d-lg-flex align-items-center'>
            <div className='col-12 col-lg-6'>
                <h2>{lang === 'en' ? homes.estrategias_heading_eng : homes.estrategias_heading}</h2>
                <p className='home-about-txt col-lg-10 col-xxl-11'>{lang === 'en' ? homes.estrategias_subheading_eng : homes.estrategias_subheading}</p>

                
                <p className='pt-lg-35 small' dangerouslySetInnerHTML={{ __html: lang === 'en' ? homes.estrategias_content_eng : homes.estrategias_content }} />

                <a href='https://wa.link/23hwxs' target='_blank'><button className='btn-secondary mt-lg-30'>{lang === 'en' ? 'Contact us via WhatsApp' : 'Contacto por WhatsApp'}</button></a>

            </div>

            <div className='offset-lg-1 col-11 col-lg-3 d-lg-flex justify-content-end mt-40 d-none d-lg-block'>
                <svg width="266" height="179" viewBox="0 0 266 179" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M177.098 0.804684L177.098 89.5663L177.236 89.5664C226.259 89.5664 265.999 49.8266 266 0.804688L177.098 0.804684Z" fill="#467DD2"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M265.931 89.5584L177.167 89.5584L177.167 89.6973C177.167 138.72 216.908 178.461 265.931 178.461C265.931 178.461 265.931 178.461 265.931 178.461L265.931 89.5584Z" fill="#FCC6E9"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M0.000103792 89.5596C3.20217e-05 89.6061 -3.8757e-06 89.6527 -3.87366e-06 89.6992C-1.7343e-06 138.642 39.6761 178.318 88.6191 178.318C137.562 178.318 177.238 138.642 177.238 89.6992C177.238 89.6527 177.238 89.6061 177.238 89.5596L0.000103792 89.5596Z" fill="#69FFAD"/>
                </svg>
            </div>
        </div>

        <div id='home-quote' className='justify-content-center d-flex home-quote'>
            <div className='col-12'>
            <h2>“</h2>
            <h2 className='pt-lg-35 small' dangerouslySetInnerHTML={{ __html: lang === 'en' ? homes.quote_content_eng : homes.quote_content }} />
            <p className='mt-40'>— {lang === 'en' ? homes.quote_author_eng : homes.quote_author}</p>
            </div>
        </div>

        <div><Countup lang={lang} /></div>
        
        <div id='home-image' className='home-image col-lg-11'>
            <img alt="ALTAIR hero" id="hero-img-full" src={`https://altairdev.s3.amazonaws.com/${homes.hero_2}`} />
        </div>

        <div id='home-divisas' className='home-divisas flex-column align-items-center'>
            <Currency lang={lang} />
        </div>

        <div id='home-slider' className='home-slider col-12 col-lg-10'>
            <Quotes lang={lang} />
        </div>

    
        <div id='home-contact' className='justify-content-center d-flex home-contact'>
            <form onSubmit={handleSubmit}>
                <div className='col-12 d-md-flex align-items-end'>
                    <div className='col-12 col-md-5 col-lg-3 offset-lg-1 home-contact-img'>
                        <figure>
                            <img alt="Contact" className="home-contact-img" id="home-contact-image" src={`https://altairdev.s3.amazonaws.com/${homes.contact_img}`}  loading='lazy' />
                        </figure>
                    </div>
                    <div className='col-12 offset-md-1 col-md-5 col-lg-6 home-contact-txt'>
                        <h3>{lang === 'en' ? homes.contact_heading_eng : homes.contact_heading}</h3>
                        <p className='mt-15 col-11 col-xxl-8' dangerouslySetInnerHTML={{ __html: lang === 'en' ? homes.contact_text_eng : homes.contact_text }} />

                        <div className='home-contact-form col-lg-8'>
                            <div className='form-input'><input type="text" name='name' placeholder={lang === 'en' ? 'Name' : 'Nombre*'} value={formData.name} onChange={handleChange} /></div>
                            <div className='form-input'><input name='email' placeholder={lang === 'en' ? 'E-Mail*' : 'E-Mail*'}  value={formData.email} onChange={handleChange}/></div>
                            <div className='form-input'><input name='phone' placeholder={lang === 'en' ? 'Phone (optional)' : 'Teléfono (opcional)'}  value={formData.phone} onChange={handleChange}/></div>
                            <div className='form-input'><textarea name='message' placeholder={lang === 'en' ? 'Message' : 'Mensaje'}  rows="4" value={formData.message} onChange={handleChange}/></div>
                        </div>
                    </div>
                </div>
                <div className='offset-md-6 offset-lg-5 col-md-5 col-lg-6 pb-80 pb-md-0'>
                    <div className='col-lg-8'>
                        <div className='text-right h-100'>
                            <span className='xsmall'>{lang === 'en' ? '*Required fields' : '*Campos indispensables'}</span>
                        </div>

                        <div>
                            <button className='btn-principal' type='submit'>{lang === 'en' ? 'Send' : 'Enviar'}</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>;
}

export default Home;