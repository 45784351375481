import React from 'react';
import {useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';


function Marqueetxt({lang}) {
    const [homes, setHome] = useState([]);
    useEffect(() => {
       fetch(`https://altair-admin.onrender.com/api/homes/1`)
          .then((response) => response.json())
          .then((data) => {
             setHome(data);
          })
    }, []);

    return <div className='about-values-marquee-txt'>
        <h3>{lang === 'en' ? homes.values_eng : homes.values }</h3>
    </div>;
}

export default Marqueetxt;