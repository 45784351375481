import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';


function Currency({lang}) {
  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src = 'https://c.mql5.com/js/widgets/quotes/widget.js?v=1';
  //   script.async = true; // or remove this line, as it defaults to true
  //   script.type = 'text/javascript';
  //   script.dataset.type = 'quotes-widget';
  //   script.innerHTML = JSON.stringify({
  //     type: 'ticker',
  //     filter: ['EURUSD', 'USDJPY', 'GBPUSD', 'AUDUSD', 'USDCAD', 'USDCHF', 'NZDUSD'],
  //     width: '100%',
  //     height: 50,
  //     id: 'quotesWidgetTicker',
  //   });

  //   document.body.appendChild(script);

  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []); 

  return (
    <>
      {/* <div id="quotesWidgetTicker" className='col-10'></div> */}
      <div className='text-center'>
      <iframe src="https://informers.instaforex.com/rate/run/x=ABC&w=300&rate=&ticker=&tv=008000_FF0000&tabs=1_1_1_0&style=1&bg=ffffff_ffffff_0_666_0_0_0_1_333333&ta=8e2b2b&tt=10_normal_000000_h_normal&tb=12_bold_ffffff_h_normal_666_1_8e2b2b_8e2b2b_0&tab=6_6&ch=undefined&cht=8e2b2b_8e2b2b&i=1&type=0" frameBorder="0" width="300" height="271" scrolling="no" title="InstaForex is an universal Forex portal for traders"></iframe><noframes><a href="https://www.instaforex.com/">Forex Portal</a></noframes><br/>
        <span>
        {lang === 'en' ? '*Data for informational purposes only. For Interbank, Wholesale and Preferential Prices, please consult your Relationship Executive.' : '*Datos para fines informativos exclusivamente. Para Precios Interbancarios, Mayoreo y Preferenciales consulte a su Ejecutivo de Relación.'}
        </span>
      </div>
      <div className='mt-40 mt-md-40 mt-text-center'>
        <Link to="https://www.mql5.com/es/quotes/currencies" target='_blank'>
          <button className='btn-secondary'>{lang === 'en' ? 'Learn more' : 'Más información'}
          </button></Link>
      </div>
    </>
  );
}

export default Currency;
