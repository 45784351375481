import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';

function Privacy({lang}) {
    const [privacy, setPrivacy] = useState([]);
    useEffect(() => {
       fetch(`https://altair-admin.onrender.com/api/privacy/1`)
          .then((response) => response.json())
          .then((data) => {
             setPrivacy(data);
          })
    }, []);

    return <div id='Politica de Privacidad' className='legals col-10'>
        <h3>{lang === 'en' ? privacy.title_eng : privacy.title}</h3>
        
        <div className="mt-10 mt-md-40 small" dangerouslySetInnerHTML={{ __html: lang === 'en' ? privacy.content_eng : privacy.content }} />

        <p className='mt-10 mt-md-40 small text-right'>{lang === 'en' ? 'LAST ISSUE DATE' : 'FECHA ÚLTIMA DE EMISION'} {lang === 'en' ? privacy.date_eng : privacy.date}</p>

    </div>;
}

export default Privacy;