import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';

function Countup({lang}) {
    const [count1, setCount1] = useState(0);
    const [count2, setCount2] = useState(0);
    const [count3, setCount3] = useState(0);
    const [numeralia, setNumeralia] = useState({}); // Initialize as an empty object

    useEffect(() => {
       fetch(`https://altair-admin.onrender.com/api/numeralia/1`)
          .then((response) => response.json())
          .then((data) => {
            setNumeralia(data);
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
    }, []);
  
    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && Object.keys(numeralia).length > 0) {
            const targetCount1 = numeralia.numberuno; 
            const targetCount2 = numeralia.numberdos; 
            const targetCount3 = numeralia.numbertres;
  
            const interval1 = setInterval(() => {
              setCount1((prevCount) => {
                if (prevCount < targetCount1) {
                  return prevCount + 1;
                } else {
                  clearInterval(interval1);
                  const interval2 = setInterval(() => {
                    setCount2((prevCount) => {
                      if (prevCount < targetCount2) {
                        return prevCount + 2;
                      } else {
                        clearInterval(interval2);
                        const interval3 = setInterval(() => {
                          setCount3((prevCount) => {
                            if (prevCount < targetCount3) {
                              return prevCount + 40;
                            } else {
                              clearInterval(interval3);
                              return prevCount;
                            }
                          });
                        }, 50);
                        return prevCount;
                      }
                    });
                  }, 50);
                  return prevCount;
                }
              });
            }, 50);
          }
        },
        { threshold: 0.5 }
      );
  
      const targetElement = document.querySelector('.count-cuentas');
      if (targetElement) {
        observer.observe(targetElement);
      }
  
      return () => observer.disconnect();
    }, [numeralia]); // Make sure to include numeralia in dependency array
  
    return (
      <div id='home-numbers' className='home-numbers'>
        <h3>{lang === 'en' ? numeralia.content_eng : numeralia.content}</h3>
        
        <div className='col-10 d-lg-flex justify-content-start home-numbers-countup'>
            <div className='col-12 col-lg-3 col-xxl-4 home-numbers-countup-cuentas'>
                <div className="count-cuentas"><h2><span>+</span>{count1.toLocaleString('en')}</h2></div>
                <p>{lang === 'en' ? numeralia.descuno_eng : numeralia.descuno}</p>
            </div>

            <div className='col-12 col-lg-3 offset-lg-1 offset-xxl-0 col-xxl-4 home-numbers-countup-cuentas'>
                <div className="count-cuentas"><h2><span>+</span>{count2.toLocaleString('en')}</h2></div>
                <p>{lang === 'en' ? numeralia.descdos_eng : numeralia.descdos}</p>
            </div>

            <div className='col-12 col-lg-3  offset-lg-1 offset-xxl-0 col-xxl-4 home-numbers-countup-cuentas'>
                <div className="count-cuentas"><h2><span>+</span>{count3.toLocaleString('en')}</h2></div>
                <p>{lang === 'en' ? numeralia.desctres_eng : numeralia.desctres}</p>
            </div>
        </div>
    </div>
    );
}

export default Countup;
