import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';


const Wa = ({ path }) => {
  const { pathname } = useLocation();
  if (pathname === "/login") return null;


  return (
    <div className='fixed-bottom wa'>
      <div className='d-flex justify-content-end'>
            <Link to="https://wa.link/23hwxs" target='_blank'>
                <div className='d-flex justify-content-end flex-row-reverse align-items-center'>
                    <div className='wa-img'><img alt="WhatsApp button" id="Whatsapp" src="/imgs/wa_bot.svg" /></div>
                    <div className='wa-txt'><span>Ir a Whatsapp</span></div>
                </div>
            </Link>
      </div>
    </div>
  );
};

export default Wa;
