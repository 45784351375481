import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {useState} from 'react';

function Contactf({lang}) {
    const [formData, setFormData] = useState({name: "",email: "",phone: "",message: ""});

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        alert(`Name: ${formData.name}, Email: ${formData.email}, Phone: ${formData.phone}, Message: ${formData.message}`
        );
    };

    return <div className='home-contact-form col-lg-8'>
            <div className='form-input'><input type="text" name='name' placeholder={lang === 'en' ? 'Name' : 'Nombre*'} value={formData.name} onChange={handleChange} /></div>
            <div className='form-input'><input name='email'placeholder={lang === 'en' ? 'E-Mail*' : 'E-Mail*'} value={formData.email} onChange={handleChange}/></div>
            <div className='form-input'><input name='phone' placeholder={lang === 'en' ? 'Phone (optional)' : 'Teléfono (opcional)'} value={formData.phone} onChange={handleChange}/></div>
            <div className='form-input'><textarea name='message' placeholder={lang === 'en' ? 'Message' : 'Mensaje'} rows="4" value={formData.message} onChange={handleChange}/></div>
        </div>;
}

export default Contactf;