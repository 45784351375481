import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


export const options = {
    plugins: {
        legend: {
            position: 'bottom',
          },
        title: {
        display: false,
        text: 'Calculadora de interés compuesto',
        },
    },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

function Calculadora() {
    const [initialDeposit, setInitialDeposit] = useState('');
    const [interestRate, setInterestRate] = useState('');
    const [years, setYears] = useState('');
    const [interestFrequency, setInterestFrequency] = useState('anual');
    const [additionalDeposits, setAdditionalDeposits] = useState('');

    let frequencyFactor = 1;

    const calculateResults = (yearsParam = parseInt(years) || 0) => {
        const yearsValue = yearsParam || 0;
        const initialDepositValue = parseFloat(initialDeposit) || 0;
        const interestRateValue = parseFloat(interestRate) / 100 || 0;
        const additionalDepositsValue = parseFloat(additionalDeposits) || 0;
  
        if (interestFrequency === 'mensual') {
        frequencyFactor = 12; // Monthly
        } else if (interestFrequency === 'quincenal') {
        frequencyFactor = 24; // Bi-weekly
        } else if (interestFrequency === 'semanal') {
        frequencyFactor = 52; // Weekly
        } else if (interestFrequency === 'diario') {
        frequencyFactor = 365; // Daily
        }
  
        const compoundInterestRate = 1 + interestRateValue / frequencyFactor;
        const numberOfCompoundingPeriods = frequencyFactor * yearsValue;
    
        let accumulatedInterest = 0;
        let currentPrincipal = initialDepositValue;
  
        for (let i = 0; i < numberOfCompoundingPeriods; i++) {
            accumulatedInterest += currentPrincipal * (compoundInterestRate - 1);
            currentPrincipal = currentPrincipal * compoundInterestRate + additionalDepositsValue;
        }
    
        // Round to 2 decimal places
        accumulatedInterest = accumulatedInterest.toFixed(2);
  
        const calculatedTotal = (
            initialDepositValue +
            parseFloat(accumulatedInterest) +
            parseFloat(calculateAdditionalDeposits(additionalDepositsValue, frequencyFactor, yearsValue))
        ).toFixed(2);
  
        return {
            calculatedInitialDeposit: initialDepositValue.toFixed(2),
            calculatedAccumulatedInterest: accumulatedInterest,
            calculatedAdditionalDeposits: calculateAdditionalDeposits(additionalDepositsValue, frequencyFactor, yearsValue),
            calculatedTotal,
        };
    };
  
    const calculateAdditionalDeposits = (additionalDeposits, frequencyFactor, years) => {
        return additionalDeposits * frequencyFactor * years;
    };
  
    const results = calculateResults();
    const [chartData, setChartData] = useState({
        labels,
        datasets: [
        {
            label: 'Depósito inicial',
            data: labels.map(() => 1),
            backgroundColor: 'rgb(70, 125, 210)',
        },
        {
            label: 'Depósito adicionales acumulados',
            data: labels.map(() => 1),
        },
        {
            label: 'Interés acumulado',
            data: labels.map(() => 1),
            backgroundColor: 'rgb(105, 255, 175)',
        },
        ],
    });

    useEffect(() => {
        const initialDepositValue = parseFloat(initialDeposit) || 0;
        const interestRateValue = parseFloat(interestRate) / 100;
        const yearsValue = parseInt(years) || 0;
        const additionalDepositsValue = parseFloat(additionalDeposits) || 0;
    
        const newData = {
            labels: Array.from({ length: parseInt(years) || 0 }, (_, index) => index + 1),
          datasets: [
            {
              label: 'Depósito inicial',
              data: labels.map(() => initialDepositValue),
              backgroundColor: 'rgb(70, 125, 210)',
            },
            {
              label: 'Depósito adicionales acumulados',
              data: labels.map((_, index) => {
                const currentYear = index + 1;
                return calculateAdditionalDeposits(additionalDepositsValue, frequencyFactor, currentYear);
              }),
              backgroundColor: 'rgb(252, 198, 233)',
            },
            {
              label: 'Interés acumulado',
              data: labels.map(() => parseFloat(results.calculatedAccumulatedInterest)),
              backgroundColor: 'rgb(105, 255, 175)',
            }
          ],
        };
    
        setChartData(newData);
    }, 
    [initialDeposit, interestRate, additionalDeposits, frequencyFactor, results.calculatedAccumulatedInterest, years]);
    
    return <div>
        <div id='calculdora' className='calculadora row col-12'>
            <div className='d-lg-flex'>
                <div className='estatuto-txt mt-40 col-12 col-lg-3'>
                    <form>
                        <p>Depósito inicial</p>
                            <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1">$</span>
                                <input
                                    type="number"
                                    className="form-control"
                                    value={initialDeposit}
                                    onChange={(e) => setInitialDeposit(e.target.value)}
                                />
                            </div>

                        <p>Tasa de interés actual</p>
                            <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1">%</span>
                                <input
                                    type="number"
                                    className="form-control"
                                    value={interestRate}
                                    onChange={(e) => setInterestRate(e.target.value)}
                                />
                            </div>

                        <p>Años a invertir</p>
                            <div className="input-group mb-3">
                                <input
                                    type="number"
                                    className="form-control"
                                    value={years}
                                    onChange={(e) => setYears(e.target.value)}
                                />
                            </div>

                        <p>Frecuencia anual de interés</p>
                            <div className="input-group mb-3">
                                <select
                                    className="form-select"
                                    aria-label="Default select example"
                                    value={interestFrequency}
                                    onChange={(e) => setInterestFrequency(e.target.value)}
                                >
                                    <option value="anual">Anualmente</option>
                                    <option value="mensual">Mensualmente</option>
                                    <option value="quincenal">Quincenalmente</option>
                                    <option value="semanal">Semanalmente</option>
                                    <option value="diario">Diariamente</option>
                                </select>
                            </div>

                        <p>Aportaciones adicionales</p>
                            <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1">$</span>
                                <input
                                    type="number"
                                    className="form-control"
                                    value={additionalDeposits}
                                    onChange={(e) => setAdditionalDeposits(e.target.value)}
                                />
                            </div>   
                    </form>
                </div>

                <div className='col-12 offset-lg-1 col-lg-8'>
                    <div>
                        <h3 className='calculadora-title'>Calculadora de interés compuesto</h3> 
                        <Bar options={options} data={chartData} />
                    </div>

                    <div>
                        <div className='calculadora-results col-12'>
                            <h3>Resultados</h3>
                            <div className='d-lg-flex justify-content-between calculadora-badges'>
                                <div className='badges-wrappers'>
                                    <div className='blue w-100'>
                                        ${parseFloat(results.calculatedInitialDeposit).toLocaleString('en-US', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                        })}
                                    </div>
                                    <span className='small'>Depósito Inicial</span>
                                </div>

                                <div>
                                    <div className='pink w-100'>
                                        ${parseFloat(results.calculatedAdditionalDeposits).toLocaleString('en-US', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                        })}
                                    </div>
                                    <span className='small'>Depósitos adicionales<br/> acumulados</span>
                                </div>

                                <div>
                                    <div className='green w-100'>
                                        ${parseFloat(results.calculatedAccumulatedInterest).toLocaleString('en-US', {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                        })}
                                    </div>
                                    <span className='small'>Interés acumulado</span>
                                </div>

                                <div>
                                    <div className='grey w-100'>
                                        ${parseFloat(results.calculatedTotal).toLocaleString('en-US', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        })}
                                    </div>
                                    <span className='small'>Total</span>
                                </div>
                            </div>
                        </div>

                        <div className='calculadora-results '>
                            <h3>Resultados por año</h3>
                            <table className='mt-60 table'>
                                <thead>
                                    <tr>
                                        <th scope="col">Años</th>
                                        <th scope="col">Depósito inicial</th>
                                        <th scope="col">Depósitos adicionales acumulados</th>
                                        <th scope="col">Interés Acumuado</th>
                                        <th scope="col">Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array.from({ length: parseInt(years) || 0 }, (_, index) => {
                                    const currentYear = index + 1;
                                    const currentResults = calculateResults(currentYear);

                                        return (
                                            <tr key={currentYear}>
                                                <th scope="row">{currentYear}</th>
                                                <td>${parseFloat(currentResults.calculatedInitialDeposit).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                <td>${parseFloat(currentResults.calculatedAdditionalDeposits).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                <td>${parseFloat(currentResults.calculatedAccumulatedInterest).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                <td>${parseFloat(currentResults.calculatedTotal).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}

export default Calculadora;