import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

function Contact() {
    return <div>
        <div id='login' className='login-wrapper col-10 col-md-8 col-lg-4 vh-100 d-flex flex-column justify-content-center align-content-center'>
            <h3>Access key:</h3>
            <form method="post">
                <div className='form-input'><input type="password" name='code' placeholder='Code' /></div>

                <div className='d-flex w-100 justify-content-end'>
                    <button className='btn-principal' type='submit'>Entrar</button>
                </div>
            </form>
        </div>
    </div>;
}

export default Contact;