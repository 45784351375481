import React from 'react';
import {useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Marquee from "react-fast-marquee";
import Marqueetxt from './Marqueetxt';


function Values({lang}) {
    const [homes, setHome] = useState([]);
    useEffect(() => {
       fetch(`https://altair-admin.onrender.com/api/homes/1`)
          .then((response) => response.json())
          .then((data) => {
             setHome(data);
          })
    }, []);


    return <div id='about-values' className='about-values col-12 col-md-12 col-lg-10 overflow-hidden'>
    <div className='about-values-mission d-flex flex-column flex-md-row align-items-center'>
        <div className='about-values-mission-title col-md-3 col-lg-3 col-xxl-4'><h2>{lang === 'en' ? 'Mission' : 'Misión'}</h2></div>
        <div className='about-values-mission-text'>
            <h3 className='home-desktop' dangerouslySetInnerHTML={{ __html: lang === 'en' ? homes.mision_eng : homes.mision }} />
            <p className='home-mobile' dangerouslySetInnerHTML={{ __html: lang === 'en' ? homes.mision_eng : homes.mision }} />
        </div>
    </div>

    <div className='about-values-vision d-flex flex-column flex-md-row align-items-center'>
        <div className='about-values-vision-title col-md-3 col-lg-3 col-xxl-4'><h2>{lang === 'en' ? 'Vision' : 'Visión'}</h2></div>
        <div className='about-values-vision-text'>
            <h3 className='home-desktop' dangerouslySetInnerHTML={{ __html: lang === 'en' ? homes.vision_eng : homes.vision }}  />
            <p className='home-mobile' dangerouslySetInnerHTML={{ __html: lang === 'en' ? homes.vision_eng : homes.vision }}  />
        </div>
    </div>

    <div className='about-values-marquee d-md-flex justify-content-between align-items-baseline gap-10'>
        <div className='col-12 col-md-2 col-lg-2 offset-xxl-1'>
            <h2>{lang === 'en' ? 'Values' : 'Valores'}</h2>
        </div>

        <div className='col-12 mmt-20'>
            <Marquee gradient={false} loop={0} gradientWidth={100}>
                <Marqueetxt lang={lang} />
                <Marqueetxt lang={lang} />
            </Marquee>
        </div>
    </div>
</div>
}

export default Values;